
body {background-color: rgba(162, 162, 162, 0.286) !important}


.wrapper{
  min-height: 100vh ! important;
  position: relative ! important;
  display: flex ! important;
  flex-direction: column ! important;
}
.menuwrapper{
  min-height: 80vh ! important;
  position: relative ! important;
  display: flex ! important;
  flex-direction: column ! important;
}