
.valignauto {
 
    
   vertical-align: baseline !important;
}



.nounderline{
   text-decoration-line: none !important;

}

