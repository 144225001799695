
.footer {
 
    border-top-style: solid;
   border-top-width: thin;
   margin-top: auto ! important;
   background: #272727; 
   border-bottom: #088172;
   border-bottom-width: 35px;
   border-bottom-style: solid;
}


